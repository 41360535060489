/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it

import './src/styles/css/bootstrap.min.css';
import "./src/styles/css/animate.min.css"
import "./src/styles/css/boxicons.min.css"
import "./src/styles/css/flaticon.css"
import 'react-accessible-accordion/dist/fancy-example.css'
import 'react-18-image-lightbox/style.css';
import "/node_modules/aos/dist/aos.css";
import "swiper/css"
import "swiper/css/bundle"

// Global style
import "./src/styles/css/style.scss"
import "./src/styles/css/responsive.scss"

export const onRouteUpdate = (args) => {
  if (typeof window !== `undefined` && args.prevLocation) {
    setTimeout(() => {
      if (args.location.hash) {
        const anchor = document.querySelector(args.location.hash);
        const offset = anchor.getBoundingClientRect().top + window.scrollY;
        window.scroll({ top: offset - 50, left: 0 });
      } else {
        window.scrollTo(0, 0);
      }
    }, 50);
  }
}

export const shouldUpdateScroll = (args) => {
  return false;
};